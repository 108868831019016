import React from "react"
import { Link } from "gatsby"
import { Box, Grid, Typography, Container } from "@material-ui/core"
import Layout from "../components/layout"
import "../styles/homepage-v1.scss"

import phoneFrontImg from "../images/app/99r-phone-home-for-website_352px.gif"
import phoneHoldingsImg from "../images/app/99r-phone-portfolio-for-website_352px.gif"
import phoneStockMemoImg from "../images/app/99r-phone-stock-for-website_352px.gif"

import NextGenImg from "../images/brects/blocks-crop-_0000_NextGen_320w.jpg"
import BalancedImg from "../images/brects/blocks-crop-_0001_Balanced_320w.jpg"
import OceansImg from "../images/brects/blocks-crop-_0002_Oceans_320w.jpg"
import CryptoImg from "../images/brects/blocks-crop-_0003_Crypto_320w.jpg"
import SecureImg from "../images/brects/blocks-crop-_0004_secure_320w.jpg"
import SafeImg from "../images/brects/blocks-crop-_0005_Safe_320w.jpg"
import IraImg from "../images/brects/blocks-crop-_0006_Traditional-IRA_320w.jpg"
import AggressiveImg from "../images/brects/blocks-crop-_0007_Aggressive_320w.jpg"
import CurrenciesImg from "../images/brects/blocks-crop-_0008_Currencies_320w.jpg"
import CommoditiesImg from "../images/brects/blocks-crop-_0009_Commodities_320w.jpg"
import MobilityImg from "../images/brects/blocks-crop-_0010_Mobility_320w.jpg"
import AIImg from "../images/brects/blocks-crop-_0011_AI_320w.jpg"
import FintechImg from "../images/brects/blocks-crop-_0012_Fintech_320w.jpg"
import OmicsImg from "../images/brects/blocks-crop-_0013_Omics_320w.jpg"

class BlockCatalog extends React.Component {
  render() {
    return (
      <Layout>
        <div className="LinkedPageBackgroundColor">
          <Container maxWidth="md">
            <Grid container>
              {BlockCell(OceansImg, "Climate Change")}
              {BlockCell(OceansImg, "Oceans")}
              {BlockCell(CryptoImg, "Crypto")}
              {BlockCell(OmicsImg, "Omics")}
            </Grid>
            <Grid container>
              {BlockCell(SecureImg, "Secure")}
              {BlockCell(SafeImg, "Safe")}
              {BlockCell(BalancedImg, "Balanced")}
              {BlockCell(AggressiveImg, "Aggressive")}
            </Grid>
            <Grid container>
              {BlockCell(IraImg, "Retirement")}
              {BlockCell(MobilityImg, "Mobility")}
              {BlockCell(AIImg, "Artificial Intelligence")}
              {BlockCell(NextGenImg, "Next Gen Internet")}
            </Grid>
            <Grid container>
              {BlockCell(CryptoImg, "Crypto")}
              {BlockCell(CurrenciesImg, "Currencies")}
              {BlockCell(OmicsImg, "Commodities")}
              {BlockCell(FintechImg, "Fintech")}
            </Grid>
          </Container>
        </div>
      </Layout>
    )
  }
}
export default BlockCatalog

function BlockCell(img, title) {
  return (
    <Grid
      item
      xs={3}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <img src={img} width={200} height={200} />
      <div style={{ maxWidth: "200px", textAlign: "center" }}>{title}</div>
    </Grid>
  )
}
